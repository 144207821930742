import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import Home from './pages/Home'
//import Kinematics from './pages/Kinematics'
//import Maps from './pages/Maps'
//import EspressoEngine from './pages/EspressoEngine'
import Portfolio from './pages/Portfolio'
import Projects from './pages/Projects'
import Research from './pages/Research'

import Navbar from './components/Navbar'


function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/research' element={<Research />} />

        
      </Routes>
    </div>
  );
}

export default App;
