import React from 'react'
import '../css/w3.css';
import '../logo.svg'

const Navbar = () => {

// name , directory/sub directory
  /*const NavList = [
    ['Projects', '/projects', 
      ['Art','art'],
      ['Engineering','engineering'], 
      ['Horticulture','horticulture']],
    ['Research','research',
      ['Entomology','entomology'],
      ['Microbiology','microbiology'],
      ['Optics','optics'],
      ['Botany','botany'],
      ['Mycology','mycology']
      ],
    ['Portfolio','/portfolio']
  ]*/


  return (
    <div className="w3-bar w3-blue-gray w3-padding w3-card">
      <a href="/" className="w3-button">PAYNE'S PICKLES</a>

      <div className="w3-right">
        <a href="/portfolio" className="w3-button">PORTFOLIO</a>
        <a href="/projects" className="w3-button">PROJECTS</a>
        <a href="/research" className="w3-button">RESEARCH</a>

        
      </div>
    </div>

  )
}

export default Navbar