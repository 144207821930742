import React from 'react'
import '../css/w3.css';
 
const Home = () => {

  return (
    <p>This page is under construction</p>
  )
}

export default Home